import { useState } from "react";

const HomePage = () => {
  const [email, setEmail] = useState('')
  const jump = () => {
    const url = 'http://admin.myoshopoo.com/register?username='
    const src = encodeURIComponent(email || '')
    window.location.href = url + src
  }
  return <div>
    <section className={`section bg-[#0A282A]`}>
      <div className={`container  flex flex-col items-center  md:items-end md:flex-row`}>
        <div className="flex flex-col flex-1 text-[white] py-[5vw] md:py-[90px]">
          <div className="text-[4vw] md:text-[20px]  flex items-center my-[1vw] md:my-1">
            <div className="w-[32px] h-[1px] bg-[#fff] mr-1"></div>
            實體店？輕鬆轉網店
          </div>
          <span className="text-[8vw] md:text-6xl font-bold my-[1vw] md:my-1">
            助力實體經濟
          </span>
          <span className="font-bold text-[8vw] md:text-6xl my-[1vw] md:my-1"
                style={{ WebkitTextStroke: '1px #ffffff', color: 'transparent' }}>
            讓生意有更多可能性
          </span>
          <div className="w-fit flex flex-col  items-center mt-2 md:mt-5">
            <div
              className="flex w-full md:w-[400px] pl-[30px] bg-white rounded-full border-[#fff]  border-solid border-2 ">
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="flex-1 text-black"
                     style={{ outline: 'none' }} placeholder="請輸入電郵"/>
              <button onClick={jump}
                      className="flex button ml-[20px] flex-row items-center px-2 tracking-wider rounded-full  min-w-[25vw] md:min-w-fit h-[11vw] md:h-[60px]  bg-[#2c695d] text-[4vw] md:text-[16px] ">免費試用
              </button>
            </div>
            <span className="text-xs my-1 md:my-2">免費試用14日（*無需提供信用卡資料）</span>
          </div>

          <div className="hidden md:flex" gap={60}>
            <div className="flex flex-col">
              <span className="text-4xl font-bold my-2" style={{ fontFamily: 'DINPro-Bold' }}>3UP+</span>
              <span className="mb-[2px] text-xs">強大預約功能</span>
              <span className="mb-[2px] text-xs">靈活sku管理</span>
              <span className="mb-[2px] text-xs">智能電子錢包（會員儲值）</span>
              <span className="mb-[2px] text-xs">會員系統</span>
            </div>

            <div className="flex flex-col ml-[40px]">
              <span className="text-4xl font-bold my-2" style={{ fontFamily: 'DINPro-Bold' }}>UX 4.8</span>
              <span className="mb-[2px] text-xs">更優用户交互體驗</span>
              <span className="mb-[2px] text-xs">視覺效果</span>
              <span className="mb-[2px] text-xs">操作簡單</span>
              <span className="mb-[2px] text-xs">無需任何編程知識</span>
            </div>

          </div>
        </div>
        <img src="/images/fun-show.png" className='w-[580px] contain'/>
      </div>
    </section>

    <section className={`section`}>
      <div className={`container   flex flex-col items-center`}>
        <h3 className="font-bold text-[6vw] md:text-4xl mt-[5vw] dm:mt-[48px] "
            style={{ fontFamily: 'DINPro-Bold, DINPro' }}>Basic functions of
          oline
          store</h3>
        <span className="my-[8px]">網店基本功能</span>
        <img className='w-[860px] mt-[1vw] md:mt-[40px]' src="/images/fun.png" alt=""/>
      </div>
    </section>

    <section className='section pb-[0] md:pb-[60px]'>
      <div className='container bg-[#0A282A] md:bg-transparent'>
        <div className="flex flex-col items-center pb-[5px] md:pb-[80px]  w-full">
          <div
            className="bg-[#0A282A] pb-[50px] md:pb-[250px] pt-[6vw] md:pt-[30px] w-full rounded-[32px] text-white flex flex-col items-center">
            <h4 className="text-[5vw] font-bold md:text-3xl my-[12px] text-white">多種特色功能融爲一體</h4>
            <p className="text-base my-[2px] px-[]">網上預約落單/香港本地熱門支付方式</p>
            <p className="text-base my-[2px]"> 會員儲值+會員支付</p>
            <p className="text-base my-[2px]">無需IT程式知識</p>
          </div>
          <div
            className=" flex flex-col md:flex-row justify-around w-full md:mt-[-160px]  md:px-[20px]  text-white">
            <div
              className="relative  w-full md:w-[30%] rounded-[16px] flex flex-col items-center  bg-gray-100 py-[40px] px-[4vw] md:px-[35px] mt-[16vw] md:mt-0">
              <div
                className="absolute w-full top-[-40px] text-center text-[4vw] md:text-sm">客戶可使用電子錢包餘額+任意支付方式
              </div>
              <div className="md:h-[290px]  w-[200px]">
                <img className="w-full" src="/images/example-1.png" alt=""/>
              </div>
              <p className="hidden md:block text-center text-black text-sm h-[120px] flex flex-col justify-center">
                VIS、Master、UnionPay銀聯、<br/>AlipayHK、八達通、PayMe for Business、<br/>WeCat
                Pay、FPS轉數快等多個本地海外 <br/>熱門付款方式
              </p>
              <p
                className="md:hidden text-center text-black text-[3.6vw] md:text-sm py-[5vw] flex flex-col justify-center">
                VIS、Master、UnionPay銀聯、AlipayHK、八達通、PayMe for Business、WeCat
                Pay、FPS轉數快等多個本地海外熱門付款方式
              </p>
              <div className="absolute w-full text-center bottom-[16px] text-xs text-[#878A87]">
                *需向支付公司申請帳戶
              </div>
            </div>
            <div
              className="relative  w-full md:w-[30%] rounded-[16px] flex flex-col items-center  bg-gray-100 py-[40px] px-[35px] mt-[16vw] md:mt-0">
              <div className="absolute w-full top-[-40px] text-center text-[4vw] md:text-base">複雜的SKU依然可以靈活管理
              </div>

              <div className="md:h-[290px]  w-[200px]">
                <img className="w-full" src="/images/example-2.png" alt=""/>
              </div>
              <p
                className="text-center text-black text-[3.6vw]  md:text-base py-[5vw] md:py-0 md:h-[120px] flex flex-col justify-center">
                靈活sku管理,批量設置價格/庫存,無限添加產品規格
              </p>
            </div>
            <div
              className="relative  w-full md:w-[30%] rounded-[16px] flex flex-col items-center  bg-gray-100 py-[40px] px-[35px] mt-[16vw] md:mt-0">
              <div className="absolute w-full top-[-40px] text-center font-xs">預約制落單（支援多門店）</div>
              <div className="md:h-[290px] w-[200px]">
                <img className="w-full" src="/images/example-3.png" alt=""/>
              </div>
              <p
                className="text-center text-black text-[3.6vw] md:text-base py-[5vw] md:py-0 md:h-[120px] flex flex-col justify-center">
                後台可針對每日/每個時間段設置可預約時間數量，提前預約等
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className={`section bg-[#0A282A] h-[55vw] md:h-[283px] overflow-visible`}>
      <div className='flex flex-row justify-between relative h-full overflow-visible pt-[5vw] md:pt-0'>
        <img src="/images/light.png" alt=""
             className=" w-[56vw] h-[50vw] ml-[-5vw] md:ml-0 md:w-[474px] md:h-[419px] md:mt-[-136px]"/>
        <div className="flex flex-col flex-1  md:flex-row justify-center items-center  md:w-auto">
          <div className="flex w-full md:w-auto items-center">
            <span className="text-[27vw] md:text-[200px] text-white leading-[28vw]"
                  style={{ fontFamily: 'DINPro-Bold' }}>0</span>
            <span className="text-3xl text-white ml-2 font-bold" style={{ fontFamily: 'DINPro-Bold' }}>%</span>
          </div>
          <div className="hidden md:block bg-white w-[1px] h-[40px] " style={{ margin: '0 30px' }}></div>
          <div className="text-white pr-[20px]">
            <span className="text-[5vw] md:text-2xl font-bold">平台零交易抽成</span> <br/>
            <span className="text-xs ">
              免收任何交易手續費，<br className="md:hidden"/> 提升商家利潤
            </span>
          </div>
        </div>
      </div>
    </section>

    <section className={`section pb-[30px] bg-white`}>
      <div className={`container   flex flex-col items-center relative text-white `}>
        <h3 className="font-bold text-[5vw] md:text-4xl mt-[48px] text-black"
            style={{ fontFamily: 'DINPro-Bold, DINPro' }}>店鋪品牌辨識度</h3>
        <span className="my-[8px] text-black">可配置屬於自己的域名/郵箱/短信發送抬頭等</span>

        <div className="w-full flex flex-col  md:flex-row mt-[40px] justify-between ">
          <div
            className="bg-[#0A282A] w-full md:w-[30%] h-[50vw] md:h-[340px] p-[4vw] md:p-[30px] rounded-[12px] relative mb-[4vw]">
            <div className="font-bold text-[6vw] md:text-4xl leading-10 mb-[8px]"
                 style={{ fontFamily: 'DINPro-Bold, DINPro' }}>平台分配網址
            </div>
            <span className=" leading-normal text-[4vw] md:text-base">在平台註冊時自己填寫的網址 <br/> 自己填寫的公司網址</span>
            <div
              className="flex items-center rounded-l-full absolute  bottom-[4vw] md:bottom-[32px] right-0 bg-white w-[90%] h-[13vw] md:h-[80px] pl-[32px] text-[#878A87] text-xl">
              分配.oshopoo.com
            </div>
          </div>

          <div
            className="bg-[#344B7C] w-full md:w-[30%] h-[50vw] md:h-[340px]  p-[4vw] md:p-[30px] rounded-[12px] relative mb-[4vw]">
            <div className="font-bold text-[6vw] md:text-4xl leading-10 mb-[8px]"
                 style={{ fontFamily: 'DINPro-Bold, DINPro' }}>獨有網址
            </div>
            <span className=" leading-normal text-[4vw] md:text-base">網店可綁定屬於自己註冊擁有的<br/> 域名地址</span>
            <div
              className="flex items-center rounded-l-full absolute  bottom-[4vw] md:bottom-[32px] right-0 bg-white w-[90%] h-[13vw] md:h-[80px] pl-[32px] text-[#878A87] text-xl  text-xl">
              自己擁有的.com
            </div>
          </div>

          <div
            className="bg-[#F5F5F5] w-full md:w-[30%] h-[50vw] md:h-[340px]  p-[4vw] md:p-[30px] rounded-[12px] relative text-black mb-[4vw]">
            <div className="font-bold text-[6vw] md:text-4xl leading-10 mb-[8px]"
                 style={{ fontFamily: 'DINPro-Bold, DINPro' }}>SSL網絡認證
            </div>
            <span
              className="leading-normal text-[4vw] md:text-base">為在平台註冊且使用平台分配的網址提供安全標識,加強客戶信任度</span>
            <div
              className="flex items-center rounded-l-full absolute  bottom-[4vw] md:bottom-[32px] right-0 bg-[#EBEBEB] w-[90%] h-[13vw] md:h-[80px] pl-[0.5vw] md:pl-[4px] text-[#878A87] text-xl">
              <div
                className="flex justify-center items-center w-[12vw] md:w-[72px] h-[12vw] md:h-[72px] mr-[10px] rounded-full bg-white">
                <img className="h-[50%]" src="/images/security.png" alt=""/>
              </div>
              <div className="text-xl">
                https://分配.oshopoo.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className={`section text-white`}
             style={{ background: "url('/images/section-last.png')", backgroundSize: 'cover' }}>
      <div className="py-[14vw] md:py-[200px] w-full">
        <div className={`container flex flex-col items-center relative`}>
          <h3 className="font-bold text-[6vw] md:text-4xl text-center text-white"
              style={{ fontFamily: 'DINPro-Bold, DINPro', lineHeight: 1.5 }}>
            太多啦！講唔曬！即刻試試啦
            <br/> 只有試過先知道
          </h3>
          <div className="w-fit flex flex-col  items-center mt-3">
            <div
              className="flex justify-between items-center w-full md:w-[400px]  pl-[30px] bg-white rounded-full border-[#fff]  border-solid border-2 ">
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="flex-1 text-black"
                     style={{ outline: 'none' }} placeholder="請輸入電郵"/>
              <button onClick={jump}
                      className="button flex items-center px-2 ml-[20px] tracking-wider rounded-full min-w-[25vw] md:min-w-fit h-[12vw]  md:h-[60px]   bg-[#2c695d]  text-[4vw] md:text-base flex-row ">免費試用
              </button>
            </div>
            <span className="text-sm  my-2">免費試用14日（*無需提供信用卡資料）</span>
          </div>
          <h3 className="font-bold text-[6vw] md:text-4xl mt-[48px] text-white"
              style={{ fontFamily: 'DINPro-Bold, DINPro' }}>
            技術夥伴</h3>
          <span className="my-[12px]">From</span>
          <div className="flex justify-around w-[71vw]  md:w-full flex-wrap mt-[30px]">
            <img className="w-[32vw] md:w-[240px] mb-[3vw] md:mb-0" src="/images/amazon-logo.png" alt=""/>
            <img className="w-[32vw] md:w-[240px] mb-[3vw] md:mb-0" src="/images/futu-logo.png" alt=""/>
            <img className="w-[32vw] md:w-[240px] mb-[3vw] md:mb-0" src="/images/tencent-logo.png" alt=""/>
            <img className="w-[32vw] md:w-[240px] mb-[3vw] md:mb-0" src="/images/alibaba-logo.png" alt=""/>
          </div>
        </div>
      </div>
    </section>
  </div>
}

export default HomePage
